import { env } from "../../utils/env";

type AnalyticsEvents = {
  init: {
    citizenship?: string;
  };
  "citizenship-choose": {
    old?: string;
    new?: string;
  };
  "visible-visa-kinds-changed": {
    old: string[];
    new: string[];
  };
  "map-click-country": {
    code: string;
    status: "found" | "no-information" | "unknown";
  };
};

declare global {
  function gtag<T extends keyof AnalyticsEvents>(
    type: "event",
    event: T,
    payload: AnalyticsEvents[T]
  ): void;
}

export class Analytics {
  static async track<T extends keyof AnalyticsEvents>(
    event: T,
    payload: AnalyticsEvents[T]
  ) {
    if (env.production) {
      window.gtag("event", event, payload);
    } else {
      console.log("analytics track");
      console.log(event);
      console.table(payload);
    }
  }
}
