import { create } from "zustand";
import { GeoJsonApi, Geojson } from "../api/geojson";
import { client } from "../utils/client";

type Store = {
  loaded: boolean;
  data: Geojson | undefined;
  setLoaded: (loaded: boolean) => void;
  setData: (data: Geojson) => void;
};

export const useGeoJsonStore = create<Store>((set) => ({
  loaded: false,
  setLoaded: (loaded) => set(() => ({ loaded })),
  data: undefined,
  setData: (data) => set(() => ({ data })),
}));

export function loadGeoJsonStore() {
  GeoJsonApi.getData()
    .then((data) =>
      useGeoJsonStore.setState({
        loaded: true,
        data,
      })
    )
    .then(() => {
      if (client.shouldSaveData) {
        return;
      }

      GeoJsonApi.getDataHighResolution().then((data) =>
        useGeoJsonStore.setState({
          loaded: true,
          data,
        })
      );
    });
}
