import React from "react";
import ReactDOM from "react-dom/client";
import "reset-css";
import reportWebVitals from "./reportWebVitals";
import { Application } from "./application";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./index.css";
import { Analytics } from "./tracking/analytics";
import { LocalStorage } from "./api/local-storage";
import { env } from "./utils/env";
import { bootstrap } from "./services/bootstrap";

if (env.production) {
  Sentry.init({
    dsn: "https://8e1914011736454e9ee512c3b77cb259@o4504663226777600.ingest.sentry.io/4504663237394432",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env.NODE_ENV,
    release: env.release,
  });
}

bootstrap();

Analytics.track("init", {
  citizenship: LocalStorage.get("citizenship"),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
