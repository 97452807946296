import React, { useMemo } from "react";
import { useCountryMapStore } from "../../store/country-map";
import { useGeoJsonStore } from "../../store/geo-json";
import styles from "./styles.module.scss";
import { useVisaRequirementsStore } from "../../store/visa-requirements";

export const Preloader: React.FC = () => {
  const visaRequirementsLoaded = useVisaRequirementsStore((i) => i.loaded);
  const geoJsonLoaded = useGeoJsonStore((i) => i.loaded);
  const countryMapLoaded = useCountryMapStore((i) => i.loaded);

  const loaded = useMemo(() => {
    return visaRequirementsLoaded && geoJsonLoaded && countryMapLoaded;
  }, [countryMapLoaded, geoJsonLoaded, visaRequirementsLoaded]);

  return !loaded ? <div className={styles.preloader}>Loading...</div> : null;
};
