import { env } from "../../utils/env";
import { VisaStatus } from "./types";

export type CountryCode = string;

export type VisaRegimesData = { [key: CountryCode]: VisaStatus };

export type PassportIndexGetDataResult = (VisaRegimesData & {
  Passport: CountryCode;
})[];

export class PassportIndexApi {
  static getData(): Promise<PassportIndexGetDataResult> {
    return window
      .fetch(env.PUBLIC_URL + "/data/visa-requirements.json")
      .then((i) => i.json());
  }
}
