import { create } from "zustand";
import { LocalStorage } from "../api/local-storage";

type Citizenship = string | undefined;

type Store = {
  citizenship: Citizenship;
  set: (citizenship: Citizenship) => void;
};

export const useCitizenshipStore = create<Store>((set) => ({
  citizenship: LocalStorage.get("citizenship"),
  set: (citizenship) => set(() => ({ citizenship })),
}));

export function setStorageOnCitizenshipStoreChanged() {
  useCitizenshipStore.subscribe((state) => {
    LocalStorage.set("citizenship", state.citizenship);
  });
}
