import React from "react";
import { Preloader } from "./components/preloader";
import { IndexPage } from "./pages/index";

export const Application: React.FC = () => {
  return (
    <>
      <Preloader />
      <IndexPage />
    </>
  );
};
