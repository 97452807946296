import { CountryCode } from "../passport-index";

type Storage = {
  citizenship: CountryCode | undefined;
};

export class LocalStorage {
  static get<T extends keyof Storage>(key: T): Storage[T] | undefined {
    try {
      return JSON.parse(window.localStorage.getItem(key)!);
    } catch {
      return undefined;
    }
  }

  static set<T extends keyof Storage>(key: T, value: Storage[T]): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}
