import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { VisaKind } from "../../domain/visa-requirements/types";
import styles from "./styles.module.scss";

type Props = {
  value: VisaKind[];
  onChange: (value: VisaKind[]) => void;
};

export const VisaKindFilter: React.FC<Props> = ({ value, onChange }) => {
  const checked = useMemo(
    () => ({
      free: value.includes("free"),
      easy: value.includes("easy"),
      visa: value.includes("visa"),
    }),
    [value]
  );

  const change = useCallback(
    (toggled: VisaKind) => {
      const index = value.indexOf(toggled);

      if (index === -1) {
        onChange([...value, toggled]);
      } else {
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
      }
    },
    [value, onChange]
  );

  return (
    <div className={styles.root}>
      <button
        title="Visa-free"
        type="button"
        className={classNames(styles.item, styles.free, {
          [styles.disabled]: !checked.free,
        })}
        onClick={() => change("free")}
      />
      <button
        title="Visa on arrival, eVisa"
        type="button"
        className={classNames(styles.item, styles.easy, {
          [styles.disabled]: !checked.easy,
        })}
        onClick={() => change("easy")}
      />
      <button
        title="Visa required"
        type="button"
        className={classNames(styles.item, styles.visa, {
          [styles.disabled]: !checked.visa,
        })}
        onClick={() => change("visa")}
      />
    </div>
  );
};
