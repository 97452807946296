import { env } from "../../utils/env";

export type Geojson = any;

export class GeoJsonApi {
  static getData(): Promise<Geojson> {
    return this.getOpenLayers();
  }

  static getDataHighResolution(): Promise<Geojson> {
    return this.getGiant();
  }

  private static getOpenLayers(): Promise<Geojson> {
    return window
      .fetch(env.PUBLIC_URL + "/data/geojson-basic.geojson")
      .then((i) => i.json());
  }

  private static getGiant(): Promise<Geojson> {
    return window
      .fetch(env.PUBLIC_URL + "/data/geojson-detailed.geojson")
      .then((i) => i.json());
  }
}
