import {
  CountryCode,
  PassportIndexGetDataResult,
  VisaRegimesData,
} from "../../api/passport-index/index";
import { VisaStatus } from "../../api/passport-index/types";
import { Country as CountryDomain } from "../country";
import { VisaKind, Country } from "./types";

export class VisaRequirements {
  public countries: Country[];

  static readonly visaKinds: VisaKind[] = ["free", "easy", "visa", "self"];

  public constructor(
    passportIndexGetDataResult: PassportIndexGetDataResult,
    citizenship: CountryCode
  ) {
    const passportIndex = passportIndexGetDataResult.findIndex(
      (i) => i.Passport === citizenship
    );

    if (passportIndex === -1) {
      throw new Error("Unknown country code");
    }

    this.countries = VisaRequirements.getCountries(
      passportIndexGetDataResult[passportIndex]
    );
  }

  private static getCountries(visaRegimes: VisaRegimesData): Country[] {
    return Object.keys(visaRegimes)
      .filter((code) => code !== "Passport") // TODO: correct to filter here?
      .map<Country>((code) => {
        const status = visaRegimes[code];
        const visaKind = VisaRequirements.getVisaKind(status);

        return {
          code,
          name: CountryDomain.getName(code) || "Unknown",
          status: status,
          statusDescription: VisaRequirements.getVisaStatusDescrpiton(status),
          visaKind,
          visaKindDescription:
            VisaRequirements.visaKindToVisaKindDescriptionMap[visaKind],
        };
      });
  }

  private static getVisaKind(status: VisaStatus): VisaKind {
    if (status === -1) {
      return "self";
    }

    if (typeof status === "number") {
      return "free";
    }

    return VisaRequirements.visaStatusToVisaKindMap[status];
  }

  private static visaStatusToVisaKindMap: Record<VisaStatus, VisaKind> = {
    "visa free": "free",
    "visa on arrival": "easy",
    "e-visa": "easy",
    "Hayya Entry Permit": "easy",
    "visa required": "visa",
    "covid ban": "visa",
    "no admission": "visa",
  };

  private static getVisaStatusDescrpiton(status: VisaStatus): string {
    if (status === -1) {
      return "Citizenship";
    }

    if (typeof status === "number") {
      return `${status} days`;
    }

    return VisaRequirements.visaStatusToVisaStatusDescriptionMap[status];
  }

  private static visaStatusToVisaStatusDescriptionMap: Record<
    VisaStatus,
    string
  > = {
    "visa free": "Visa-free",
    "visa on arrival": "Visa on arrival",
    "e-visa": "eVisa",
    "Hayya Entry Permit": "Hayya Entry Permit",
    "visa required": "Visa",
    "covid ban": "Covid-19 ban",
    "no admission": "No admission",
  };

  private static visaKindToVisaKindDescriptionMap: Record<VisaKind, string> = {
    self: "Citizenship",
    free: "Visa-free",
    easy: "eVisa, on arrival",
    visa: "Required",
  };
}
