import { create } from "zustand";
import {
  CountryCode,
  PassportIndexApi,
  PassportIndexGetDataResult,
} from "../api/passport-index";
import { VisaRequirements } from "../domain/visa-requirements";
import { useMemo } from "react";

type Store = {
  loaded: boolean;
  data: PassportIndexGetDataResult | undefined;
  setLoaded: (loaded: boolean) => void;
  setData: (data: PassportIndexGetDataResult) => void;
};

export const useVisaRequirementsStore = create<Store>((set) => ({
  loaded: false,
  setLoaded: (loaded) => set(() => ({ loaded })),
  data: undefined,
  setData: (data) => set(() => ({ data })),
}));

export function useCountries(): CountryCode[] | undefined {
  const visaRequirements = useVisaRequirementsStore((i) => i.data);

  return useMemo(
    () => visaRequirements?.map((i) => i.Passport),
    [visaRequirements]
  );
}

export function useVisaRequirements(
  countryCode: CountryCode | undefined
): VisaRequirements | undefined {
  const visaRequirements = useVisaRequirementsStore((i) => i.data);

  return useMemo(
    () =>
      visaRequirements && countryCode
        ? new VisaRequirements(visaRequirements, countryCode)
        : undefined,
    [visaRequirements, countryCode]
  );
}

export function loadVisaRequirementsStore() {
  PassportIndexApi.getData().then((data) =>
    useVisaRequirementsStore.setState({
      loaded: true,
      data,
    })
  );
}
