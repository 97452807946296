import classNames from "classnames";
import { useCombobox } from "downshift";
import React, { memo, useMemo, useRef, useState } from "react";
import { CountryCode } from "../../api/passport-index";
import { useCitizenshipStore } from "../../store/citizenship";
import { Country } from "../../domain/country";
import { useCountries } from "../../store/visa-requirements";
import { Analytics } from "../../tracking/analytics";
import { IconAngleDown } from "../icons/angle-down";
import styles from "./styles.module.scss";

type Option = {
  code: CountryCode;
  codeLowercase: string;
  name: ReturnType<typeof Country.getName>;
};

function useOptions(inputValue: string) {
  const countries = useCountries();

  const availableOptions = useMemo(() => {
    return (countries || []).map((code) => ({
      code,
      codeLowercase: code.toLowerCase(),
      name: Country.getName(code),
    }));
  }, [countries]);

  const filteredOptions = availableOptions.filter(
    (i) =>
      i.name?.toLowerCase().trim().indexOf(inputValue.toLowerCase().trim()) ===
      0
  );

  return [availableOptions, filteredOptions] as const;
}

export const CitizenshipAutocomplete: React.FC = () => {
  const ref = useRef<HTMLInputElement>(null);

  const [filterValue, setFilterValue] = useState("");

  const citizenshipStore = useCitizenshipStore();
  const [availableOptions, filteredOptions] = useOptions(filterValue);

  const selectedItem =
    availableOptions.find((i) => i.code === citizenshipStore.citizenship) ||
    null;

  const {
    isOpen,
    highlightedIndex,
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    selectItem,
  } = useCombobox<Option>({
    items: filteredOptions,
    itemToString: (item) => item?.name || "",
    onInputValueChange: (changes) => setFilterValue(changes.inputValue || ""),
    selectedItem,
    onSelectedItemChange: (i) => {
      const newValue = i.selectedItem?.code;

      citizenshipStore.set(newValue);

      Analytics.track("citizenship-choose", {
        old: citizenshipStore.citizenship,
        new: newValue,
      });
      ref.current?.blur();
    },
  });

  const onBlur = () => selectItem(selectedItem || null);

  return (
    <div className={styles.autocomplete}>
      <input
        className={styles.input}
        placeholder="Citizenship"
        {...getInputProps({ onBlur, ref })}
      />
      <button
        className={styles.button}
        type="button"
        {...getToggleButtonProps()}
      >
        <div className={classNames(styles.icon, { [styles.opened]: isOpen })}>
          <IconAngleDown size={24} />
        </div>
      </button>
      <ul {...getMenuProps()} className={styles.dropdown}>
        {isOpen &&
          filteredOptions.map((option, index) => (
            <li
              className={classNames(styles.dropdownItem, {
                [styles.highlighted]: highlightedIndex === index,
              })}
              key={option.code}
              {...getItemProps({ item: option, index })}
            >
              <div
                className={`fib fis fi-${option.codeLowercase} ${styles.flag}`}
              />
              <div className={styles.name}>{option.name}</div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export const MemoizedCitizenshipAutocomplete = memo(CitizenshipAutocomplete);
