import { create } from "zustand";

type Store = {
  loaded: boolean;
  set: (loaded: boolean) => void;
};

export const useCountryMapStore = create<Store>((set) => ({
  loaded: false,
  set: (loaded) => set(() => ({ loaded })),
}));
