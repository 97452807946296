import React, { useCallback, useState } from "react";
import { MemoizedCitizenshipAutocomplete } from "../../components/citizenship-autocomplete";
import { CountryMap } from "../../components/country-map";
import { VisaKindFilter } from "../../components/visa-kind-filter";
import { useCitizenshipStore } from "../../store/citizenship";
import { useVisaRequirements } from "../../store/visa-requirements";
import { VisaKind } from "../../domain/visa-requirements/types";
import { Analytics } from "../../tracking/analytics";
import styles from "./styles.module.scss";

export const IndexPage: React.FC = () => {
  const citizenship = useCitizenshipStore((i) => i.citizenship);

  const [visibleVisaKinds, setVisibleVisaKinds] = useState<VisaKind[]>([
    "free",
    "easy",
    "visa",
    "self",
  ]);

  const changeVisibleVisaKinds = useCallback(
    (newValue: typeof visibleVisaKinds) => {
      setVisibleVisaKinds((old) => {
        Analytics.track("visible-visa-kinds-changed", { old, new: newValue });
        return newValue;
      });
    },
    []
  );

  const visaRequirements = useVisaRequirements(citizenship);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.item}>
          <MemoizedCitizenshipAutocomplete />
        </div>
        <div className={styles.item}>
          <VisaKindFilter
            value={visibleVisaKinds}
            onChange={changeVisibleVisaKinds}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.map}>
          <CountryMap
            countries={visaRequirements?.countries}
            visibleVisaKinds={visibleVisaKinds}
          />
        </div>
      </div>
    </div>
  );
};
