import React, { ComponentProps, Suspense } from "react";
const Component = React.lazy(() =>
  import("./component").then((i) => ({ default: i.CountryMap }))
);

const Loader = (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    Loading
  </div>
);

export const CountryMap: React.FC<ComponentProps<typeof Component>> = (
  props
) => {
  return (
    <Suspense fallback={Loader}>
      <Component {...props} />
    </Suspense>
  );
};
